import '../animate.css';
import './styles/chat-widget.css';
import './styles/chat-widget-modal.css';
import { removeChatBubble, initializeChatWidget, showUnreadMessage, openNotification, closeNotification, toggleMaximize } from './chat'
import { getClientId, getBotConfig } from './chat/api'
import { openModal } from './chat/modal';

const iframe = document.createElement('iframe');
const widgetPath = '/solo/widget/#/';
var hostURL = "http://localhost:3000";
var eventMethod = window.addEventListener
  ? "addEventListener"
  : "attachEvent";
var eventer = window[eventMethod];
var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
let widgetIconClicked = false;
let appDetails = null;
let queryString = window.location.search;
let userMetaInfo = null;
let rootElementInfo = null;
let checkConnection = "online"
let baseURL = null

let chatInstance = {
  agentTakeOver: false,
  iframe: document.createElement('iframe'),
  botConfig: null,
  chatAPIBaseURL: null,
  unreadMsgCount: 0,
  connectToMQTTTimer: null,
  chatAgentTakeOver: false,
  widgetIconClicked: false,
  appDetails: null
}

eventer(messageEvent, event => {
  // const iframeWidget = document.getElementById('a8_solo_widget_frame');\
  if (event.data.eventName === 'GET_SESSION') {
    let sessions = {}
    event.data.keys.forEach((e) => {
      sessions[e] = {};
      if(event.data.mode === "flow" || e === "chat") {
        sessions[e] = sessionStorage.getItem(e)
      } else {
        const soloChatData = JSON.parse(sessionStorage.getItem("chat")).solo
        sessions[e] = soloChatData[e]
      }
    });
    postMessage('SESSION', { sessions, 'id': event.data.id }, event.data.mode);
  } else if (event.data.eventName === 'SET_SESSION') {
    if(event.data.mode === "flow" || event.data.key === "chat") {
      sessionStorage.setItem(event.data.key, event.data.value);
    } else {
       const soloChatData = JSON.parse(sessionStorage.getItem("chat"))
       if(!("solo" in soloChatData)) {
         soloChatData.solo = {}
       }
       soloChatData.solo[event.data.key] = event.data.value
       sessionStorage.setItem("chat", JSON.stringify(soloChatData));
    }
  } else if (event.data.eventName === "REMOVE_SESSION") {
    if(event.data.mode === "flow" || event.data.key === "chat") {
      sessionStorage.removeItem(event.data.key)
    } else {
      const soloChatData = JSON.parse(sessionStorage.getItem("chat"))
      if(!("solo" in soloChatData)) {
         soloChatData.solo = {}
      }
      delete soloChatData.solo[event.data.key] 
      sessionStorage.setItem("chat", JSON.stringify(soloChatData));
    }
  } else if (event.data.eventName === 'close') {
    const targetIframe = event.data.mode === "flow" ? iframe : chatInstance.iframe
    closeWidgetHelper(targetIframe, event.data.mode)
  } else if (event.data.eventName === "open") {
    const iframeWidget = document.getElementById('a8_solo_widget_frame');
    if (iframeWidget) {
      //iframe.style.height = 600 + 'px';
      iframeWidget.classList.remove('a8_hidden');
      iframeWidget.style.display = 'block';
      iframeWidget.classList.add('a8_slideInUp');
      iframeWidget.classList.remove('a8_slideOutDown');
      // send postMessage to start MQTT connection
      if (widgetIconClicked === false) {
        widgetIconClicked = true;
        postMessage('CONNECT_TO_MQTT', null, event.data.mode)
      }
    }
  } else if (event.data.eventName === "clearSessions") {
    onCloseSession(false)
  } else if (event.data.eventName === "SESSION_STARTED") {
    const targetAppDetails = event.data.mode === "flow" ? appDetails : chatInstance.appDetails
    const clientId = sessionStorage.getItem("clientId")
    const privateBucket = sessionStorage.getItem("privateBucket")
    const config = {
      envType: window.A8_SOLO_WEB_ENV_TYPE,
      checkConnection: checkConnection,
      ...targetAppDetails, 
      userMetaInfo, 
      rootElementInfo, 
      queryString, 
      privateBucket, 
      clientId,
      baseUrl: hostURL.includes('localhost') ? `https://${targetAppDetails.orgName}-flow.autonom8.com` : hostURL.replace(/\/$/, '') 
    }
    if(event.data.mode === "chat") {
      const soloChatData = JSON.parse(sessionStorage.getItem("chat")).solo
      config.sessionId = soloChatData.sessionId
      config.appId = soloChatData.appId
      config.privateBucket = soloChatData.privateBucket
      config.clientId = soloChatData.clientId
      config.chat.config.baseURL = chatInstance.chatAPIBaseURL
      config.windowWidth = window.innerWidth
      config.botConfig = chatInstance.botConfig
    }
    postMessage("INITIATED", { config }, event.data.mode)
  } else if(event.data.eventName === 'OPEN_MODAL') {
    openModal(event.data)
  } else if(event.data.eventName === 'NEW_CHAT_MESSAGE') {
    if (chatInstance.iframe.classList.contains("a8_hidden") && event.data.count > 0) {
      chatInstance.unreadMsgCount += event.data.count ?? 1
      showUnreadMessage(chatInstance.unreadMsgCount)
    }
  } else if(event.data.eventName === 'TOGGLE_MAXIMIZE_WIDGET') {
    const mode = event.data.mode
    toggleMaximize(event.data.theme, (event) => {
      postMessage('SEND_PREFLIGHT_EVENT', {
        eventName: `chatWidget.${event}`
      }, mode)
    })
  } else if(event.data.eventName === 'CONNECT_TO_MQTT_ACK') {
    clearInterval(chatInstance.connectToMQTTTimer)
  } else if(event.data.eventName === 'CHAT_AGENT_TAKE_OVER') {
    chatInstance.chatAgentTakeOver = event.data.agentTakeOver
  }
});

window.addEventListener("resize", () => {
  if(!chatInstance.widgetIconClicked) {
    return
  }
  if (window.innerWidth < 568) { // mobile viewport
    postMessage("HIDE_MAXIMIZE_ICON", null, "chat")
  } else {
    postMessage("SHOW_MAXIMIZE_ICON", null, "chat")
  }
});

async function initVars(config) {
  if (document.getElementById("a8_solo_script")) {
    hostURL = document.getElementById("a8_solo_script").getAttribute("src").replace("/solo/main.bundle.js", "");
  } else if (window.origin.indexOf('autonom8.com') > -1) {
    hostURL = window.origin;
  }
  baseURL = hostURL.includes('localhost') ? `https://${config.orgName}-flow.autonom8.com` : hostURL.replace(/\/$/, '') 
  userMetaInfo = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    url: window.location.href,
    channel: navigator.appCodeName ? 'web' : 'Unknown',
    metadata: {
      time: new Date(),
      lang: navigator.language,
      os: getOsName(),
      deviceName: getDeviceName(),
      browserName: getDeviceBrowserName(),
      browserVersion: getDeviceBrowserVersion(),
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    },
  };
  if(config.dimension) {
    userMetaInfo.widgetHeight = config.dimension.height
    userMetaInfo.widgetWidth = config.dimension.width
  }
  await loadEnvJS(hostURL);
  console.log('will be called on init...');
  iframe.src = hostURL + widgetPath + "?__mode__=flow"
  chatInstance.iframe.src = hostURL + widgetPath + "?__mode__=chat"
}

export const onInit = async config => {
  await initVars(config)
  appDetails = {
    ...config,
    mode: "flow"
  }
  try {
    if (
      (config.dimension &&
        config.dimension.width &&
        config.dimension.height)
    ) {
      const findDevice = getDeviceName();
      config.isMobile = findDevice.includes("Phone") || findDevice.includes("iPhone");
      const rootElement = document.getElementById(config.renderDomId);
      if (rootElement) {
        rootElementInfo = {
          offsetHeight: rootElement?.offsetHeight,
          offsetLeft: rootElement?.offsetLeft,
          offsetTop: rootElement?.offsetTop,
          offsetWidth: rootElement?.offsetWidth
        }
      }
      // iframe.onload = () => {
      //   console.log(sessionStorage.getItem("sessionStarted"))
      //   if (sessionStorage.getItem("sessionStarted")) {
      //     sessionStorage.setItem("sessionStarted", false)
      //     iframe.contentWindow.postMessage(
      //       {
      //         config: {
      //           envType: window.A8_SOLO_WEB_ENV_TYPE,
      //           ...config, userMetaInfo, queryString, rootElementInfo,
      //           ...{ baseUrl: hostURL.includes('localhost') ? `https://${config.orgName}-flow.autonom8.com` : hostURL.replace(/\/$/, '') },
      //         },
      //         event: 'INITIATED',
      //       },
      //       hostURL + widgetPath
      //     );
      //   }
      // };
      iframe.id = 'a8_solo_widget_frame';
      iframe.style.border = 'none';
      iframe.style.zIndex = '9900';
      iframe.style.transition = 'height 0.15s';
      iframe.style.overflow = 'hidden';
      iframe.style.borderRadius = config.containerTheme.borderRadius + "px"
      iframe.style.width = config.renderDomId
        ? '100%'
        : (findDevice.includes("Phone") || findDevice.includes("iPhone")) ? "100%" : config.dimension.width + 'px';
      iframe.style.height = config.renderDomId
        ? '100%'
        : (findDevice.includes("Phone") || findDevice.includes("iPhone")) ? "100%" : config.dimension.height + 'px';
      iframe.allow = "clipboard-read; clipboard-write; camera; microphone;geolocation;";
      const url = window?.location?.href;
      const requiredKeys = ['hjVerifyInstall', 'hjVerifyUUID'];
      const params = new URLSearchParams(new URL(url).search);
      if (params.has("hjVerifyInstall") && params.has("hjVerifyUUID")) {
        params.forEach((value, key) => {
          if (!requiredKeys.includes(key)) {
            params.delete(key);
          }
        });
        iframe.src = `${iframe.src}&${params.toString()}`
      }
      const classesToAdd = ['solo-autonom8-widget-frame'];
      iframe.classList.add(...classesToAdd);
      if (config.renderDomId) {
        document.getElementById(config.renderDomId).appendChild(iframe);
        iframe.style.position = 'static';
        iframe.style.boxShadow = 'none';
      } else {
        iframe.classList.add('a8_animated');
        if (!config.hideWidgetIcon) {
          iframe.classList.add('a8_hidden');
          const widgetIcon = document.createElement('div');
          widgetIcon.id = "solo_widget_icon";
          const widgetIconImg = document.createElement('img');
          if (config.containerTheme.widgetIcon.url) {
            widgetIconImg.src = config.containerTheme.widgetIcon.url;
          }
          widgetIcon.style.position = 'fixed';
          widgetIcon.style.zIndex = 9899;
          widgetIcon.style.cursor = "pointer"
          widgetIcon.style.right = config.containerTheme.widgetIcon.position.right + "px" || '10px';
          widgetIcon.style.bottom = config.containerTheme.widgetIcon.position.bottom + "px" || '10px';
          widgetIcon.appendChild(widgetIconImg);
          document.body.appendChild(widgetIcon);
          widgetIconImg.onclick = () => {
            const iframeWidget = document.getElementById('a8_solo_widget_frame');
            if (iframeWidget) {
              //iframe.style.height = 600 + 'px';
              iframeWidget.classList.remove('a8_hidden');
              iframeWidget.style.display = 'block';
              iframeWidget.classList.add('a8_slideInUp');
              iframeWidget.classList.remove('a8_slideOutDown');

              // send postMessage to start MQTT connection
              if (widgetIconClicked === false) {
                widgetIconClicked = true;
                postMessage("CONNECT_TO_MQTT", null, "flow")
              }
            }
          };
          // widgetIconImg.onclick = () => {
          //   if (!toggleWidget) {
          //     iframe.style.height = config.dimension.height + "px";
          //     toggleWidget = true;
          //   } else {
          //     iframe.style.height = 0 + "px";
          //     toggleWidget = false;
          //   }
          // }
        }
        iframe.style.display = 'none';
        iframe.style.position = 'fixed';
        iframe.style.right = (findDevice.includes("Phone") || findDevice.includes("iPhone")) ? "0px" : '10px';
        iframe.style.bottom = (findDevice.includes("Phone") || findDevice.includes("iPhone")) ? "0px" : '10px';
        iframe.style.boxShadow =
          ' 0 6px 20px #72788547';
        document.body.appendChild(iframe);
      }
    } 
  } catch(err) {
    console.error("error: onInit:", err)
  }
};

const loadEnvJS = (hostURL) => {
  return new Promise((res) => {
    const envURL =
      window.location.origin.indexOf('localhost') > -1 && window.location.origin.indexOf('3001') === -1
        ? '/env.js'
        : `${hostURL}/solo/env.js`;
    loadJS(envURL, () => { res("loaded env") }, document.head);
  })
};

const loadJS = (url, implementationCode, location) => {
  //url is URL of external file, implementationCode is the code
  //to be called from the file, location is the location to
  //insert the <script> element

  const scriptTag = document.createElement('script');
  scriptTag.src = url;

  scriptTag.onload = implementationCode;
  scriptTag.onreadystatechange = implementationCode;

  location.appendChild(scriptTag);
};

const getOsName = () => {
  var Name = "unknown";
  if (navigator.appVersion.indexOf("Mac") != -1) Name =
    "MacOS"
  if (navigator.appVersion.indexOf("Win") != -1) Name =
    "Windows OS";
  if (navigator.appVersion.indexOf("X11") != -1) Name =
    "UNIX OS";
  if (navigator.appVersion.indexOf("Linux") != -1) Name =
    "Linux OS";
  return Name
}

const getDeviceBrowserName = () => {
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return 'Firefox';
  } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
    return 'IE';
  } else if ((navigator.userAgent.indexOf("Mozilla") != -1)) {
    return "Mozilla"
  }
  else {
    return 'Unknown';
  }
}

const getDeviceBrowserVersion = () => {
  let version = "0.0" // fallback
  const { userAgent } = navigator
  const identifiers = ['OPR/', 'Firefox/', 'Edg/', 'Chrome/', 'Safari/']
  for(let i = 0; i < identifiers.length; i++) {
    const identifier = identifiers[i]
    const matched = userAgent.includes(identifier)
    if(matched) {
      const re = new RegExp(`${identifier}[0-9.]+`, "g")
      const matches = userAgent.match(re)
      if(matches) {
        version = matches[0].replace(identifier, "")
        break
      }
    }
  }
  return version
}

const getDeviceName = () => {
  if (navigator.userAgent.toLocaleLowerCase().indexOf('android') > -1 && navigator.userAgent.toLocaleLowerCase().indexOf('mobile') > -1) {
    return "Phone"
  } else if (navigator.userAgent.toLocaleLowerCase().indexOf('android') > -1) {
    return "Tab"
  } else if (navigator.userAgent.toLocaleLowerCase().indexOf('linux') > -1 || navigator.userAgent.toLocaleLowerCase().indexOf('windows') > -1 || navigator.userAgent.toLocaleLowerCase().indexOf('macintosh') > -1) {
    return "System";
  } else if (navigator.userAgent.toLocaleLowerCase().indexOf('iphone') > -1) {
    return "iPhone";
  } else if (navigator.userAgent.toLocaleLowerCase().indexOf('ipad') > -1) {
    return "iPod";
  } else if (navigator.userAgent.toLocaleLowerCase().indexOf('ipod') > -1) {
    return "iPad";
  } else {
    return "Unknown";
  }
}

const postMessage = (eventName, data, mode = "flow") => {
  const targetIframe = mode === "flow" ? iframe : chatInstance.iframe
  if(!targetIframe?.contentWindow) {
    return null
  }
  targetIframe.contentWindow.postMessage(
    {
      event: eventName,
      ...data
    },
    hostURL + widgetPath,
  );
}

function internetConnectionCheck() {
  if (navigator.onLine) {
    checkConnection = "online"
    iframe.contentWindow.postMessage(
      {
        event: "online",
      },
      hostURL + widgetPath
    );
  } else {
    checkConnection = "offline"
    iframe.contentWindow.postMessage(
      {
        event: "offline",
      },
      "*",
    );
  }
}

function closeWidgetHelper(iframe, mode) {
  if(!iframe) {
    return
  }
  iframe.classList.remove('a8_slideInUp');
  iframe.classList.add('a8_hidden');
  iframe.classList.add('a8_slideOutDown');
  iframe.addEventListener('animationend', function () {
    if (iframe.classList.contains('a8_slideOutDown')) {
      iframe.classList.remove('a8_hidden');
      iframe.style.display = 'none';
    }
  });
  if(mode === "chat") {
    const widgetEl = document.getElementById("solo-widget-icon-wrapper")
    if(widgetEl) {
      widgetEl.classList.remove("a8_hidden")
    }
  }
}

window.addEventListener('offline', internetConnectionCheck);
window.addEventListener('online', internetConnectionCheck);


export const next = () => {
  iframe.contentWindow.postMessage({
    event: 'ON_NEXT',
  },
    hostURL + widgetPath
  );
}
export const goBack = () => {
  iframe.contentWindow.postMessage({
    event: 'ON_PREVIOUS',
  },
    hostURL + widgetPath
  );
}
export const onCloseSession = (removeIframe = true) => {
  sessionStorage.removeItem('sessionId');
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('clientId');
  sessionStorage.removeItem('endSession');
  sessionStorage.removeItem("eventId")
  sessionStorage.removeItem("checkConnection")
  sessionStorage.removeItem("chat")

  const iframes = [iframe, chatInstance.iframe]

  iframes.forEach(iframe => {
    if (iframe && removeIframe) {
      iframe.classList.remove('a8_slideInUp');
      iframe.classList.remove('a8_hidden');
      iframe.classList.remove('a8_slideOutDown');
      if (document.getElementById("solo_widget_icon")) {
        document.getElementById("solo_widget_icon").remove();
      }
      widgetIconClicked = false;
      chatInstance.widgetIconClicked = false
      iframe.contentWindow?.postMessage({
        event: 'DISCONNECT_MQTT',
      },
        "*",
      );
      iframe.remove();
    }
  })
}

const openChat = () => {
  const iframe = chatInstance.iframe
  if(!iframe.contentWindow || !iframe.classList.contains("a8_hidden")) {
    return
  }
  const icon = document.getElementById("solo-widget-icon-wrapper");
  if (icon) {
    icon.classList.add("a8_hidden");
  }
  removeChatBubble();
  chatInstance.unreadMsgCount = 0
  const badge = document.querySelector(".a8-badge");
  if (badge) {
    badge.parentNode.removeChild(badge);
  }
  iframe.classList.remove('a8_hidden');
  iframe.style.display = 'block';
  iframe.classList.add('a8_slideInUp');
  iframe.classList.remove('a8_slideOutDown');
  if (chatInstance.widgetIconClicked === false) {
    // there is a possibility that the click event would have happened
    // before the iframe initialized. so keep sending the event, until
    // we get back an *ack*
    chatInstance.widgetIconClicked = true;
    chatInstance.connectToMQTTTimer = setInterval(() =>{
      postMessage('CONNECT_TO_MQTT', null, "chat")
    }, 300)
  } else if(!chatInstance.chatAgentTakeOver) {
    postMessage('SEND_PREFLIGHT_EVENT', {
      eventName: 'chatWidget.onOpen'
    }, "chat")
  }
}

async function onInitChat(config) {
  await initVars(config)
  const env = window["A8_SOLO_WEB_ENV_TYPE"]
  const pathSegment = (env === "testing" || env === "staging") ? `-${env}` : ""
  chatInstance.chatAPIBaseURL = `https://livechat${pathSegment}.autonom8.com/apis`

  // get client id
  const chatData = JSON.parse(sessionStorage.getItem("chat") ?? '{}')
  let solo = chatData.solo ?? {};
  if(!solo.clientId) {
    try {
      const clientRes = await getClientId(baseURL, config.apiKey, userMetaInfo)
      solo.clientId = clientRes.clientId
      solo.privateBucket = clientRes.privateBucket
    } catch(err) {
      throw err
    }
  }

  // get bot configuration
  try {
    chatInstance.botConfig = await getBotConfig(chatInstance.chatAPIBaseURL, config.apiKey, config, solo.clientId)
  } catch(err) {
    throw err
  }

  chatInstance.appDetails = {
    orgName: config.orgName,
    apiKey: config.apiKey,
    mode: "chat",
    isMobile: true, // for rendering the forms with full height
    chat: "user" in chatData ? chatData : {
      solo,
      user: {
        name: config.name,
        email: config.email,
        userId: config.userId,
      },
      theme: config.theme ?? {},
      config: {
        baseURL: chatInstance.chatAPIBaseURL,
        orgId: config.orgId,
        botHandle: config.botHandle,
        apiKey: config.apiKey,
        orgName: config.orgName,
        isMobile: config.isMobile ?? false,
        isLive: config.isLive ?? false,
      },
      customFields: config.customFields ?? {},
      fn: {}
    }
  }
  const appDetails = chatInstance.appDetails
  if(config.chatId) {
    appDetails.chat.id = config.chatId
  }
  if(config.widgetUIState) {
    appDetails.chat.widgetUIState = config.widgetUIState
  }
  if(config.onLoadComplete) {
    appDetails.chat.fn.onLoadComplete = config.onLoadComplete.toString()
  }
  if(config.onClose) {
    appDetails.chat.fn.onClose = config.onClose.toString()
  }
  if(config.customFields) {
    userMetaInfo.customFields = config.customFields
  }
  if(!("user" in chatData)) {
    sessionStorage.setItem("chat", JSON.stringify(appDetails.chat))
  }
  
  initializeChatWidget(chatInstance.iframe, chatInstance.botConfig, () => {
    if(config.onLoadComplete) {
      try {
        const onLoadCompleteFn = eval(config.onLoadComplete.toString())
        onLoadCompleteFn();
      } catch(err) {
        console.error("error: evaluating onLoadComplete fn:", err)
      }
    }
  })
}

export const chat = {
  openChat,
  closeChat: () => {
    closeWidgetHelper(chatInstance.iframe, "chat")
  },
  onInit: onInitChat,
  openNotification,
  closeNotification,
}

import { mergeTheme } from './'

export async function getClientId(baseURL, apiKey, userMetaInfo) {
  const url = `${baseURL}/a8solo/v1/newClient`
  const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': apiKey
      },
      body: JSON.stringify(userMetaInfo),
  };
  try {
    const res = await fetch(url, options)
    if(!res.ok) {
      throw new Error(`failed to get client id: received status code: ${res.status}`)
    }
    const data = await res.json()
    return data
  } catch(err) {
    throw new Error("failed to get client id")
  }
}

export async function getBotConfig(baseURL, appKey, config, clientId) {
  const { orgId, botHandle } = config
  const url = `${baseURL}/client/v1/configuration`
  const options = {
      headers: {
        'X-App-Key': appKey,
        'X-Bot-Handle': botHandle,
        'X-Org-Id': orgId,
        'X-Client-Id': clientId,
      },
  };
  try {
    const res = await fetch(url, options)
    if(!res.ok) {
      throw new Error(`failed to get bot configuration: received status code: ${res.status}`)
    }
    const data = await res.json()
    const theme = await mergeTheme(data.configuration, config.theme)
    data.configuration.theme = theme
    return data.configuration
  } catch(err) {
    throw new Error("failed to get bot configuration")
  }
}
